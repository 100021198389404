import * as Constants from './constants'
import * as I18n from '@rushplay/i18n'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {UnsupportedTransactionError} from './errors'

/**
 * UI-suger page for when app is started with
 * an transaction-type that's not supported
 * @component UnsupportedTransactionType
 * @returns {ReactNode}
 */
export function UnsupportedTransactionType() {
  const translate = I18n.useTranslate()

  if (process.env.NODE_ENV === 'production') {
    throw new UnsupportedTransactionError()
  }

  return (
    <ThemeUi.Flex
      sx={{
        flexGrow: 1,
        flexDirection: 'column',
        maxWidth: '250px',
        alignItems: 'center',
        mx: 'auto',
        fontSize: 1,
        pt: 2,
      }}
    >
      <ThemeUi.Box
        sx={{
          color: 'danger',
          alignSelf: 'center',
          fontSize: '120px',
          pb: 2,
        }}
      >
        <Icons.Error />
      </ThemeUi.Box>
      <ThemeUi.Text
        sx={{
          fontWeight: 'bold',
          pb: 0,
          textAlign: 'center',
        }}
      >
        {translate('unsupported-transaction-type.title')}
        <br />
        <br />
        {translate('unsupported-transaction-type.supported-types')}
      </ThemeUi.Text>
      {R.map(
        (item) => (
          <code key={item}>{item}</code>
        ),
        Constants.acceptableTransactionTypes
      )}
    </ThemeUi.Flex>
  )
}

I18n.Loader.preload(
  [
    'unsupported-transaction-type.title',
    'unsupported-transaction-type.supported-types',
  ],
  UnsupportedTransactionType
)
