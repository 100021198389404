import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Icons from '../icons'
import * as R from 'ramda'
import * as React from 'react'
import * as Suspense from '../suspense'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'
import {validateCardNumber} from '../validate-card-number'

/**
 * Object to map validator to reusable translation-key
 * Used instead of adding the same content to hundreds of keys
 * as translation variables are the only difference between them
 * @constant {Object}
 */
const validatorKeys = {
  'maximum': 'field-validation.maximum',
  'max-length': 'field-validation.max-length',
  'minimum': 'field-validation.minimum',
  'min-length': 'field-validation.min-length',
  'pattern': 'field-validation.pattern',
  'required': 'field-validation.required',
}

/**
 * Component to display errors of specified field
 * @component _FieldResponse
 * @param {Object} props Components props
 * @param {string} props.scope Scope of field to show info about
 * @returns {ReactNode} Div with first available error of specified field
 */
function _FieldResponse(props) {
  const field = Forms.useField(props.scope, {noRegister: true})
  const firstError = R.head(field.errors)
  // Regex matches everything after last `.`
  // Which is always the validators name
  const errorKey =
    validatorKeys[
      R.match(/[^.]+$/, R.pathOr('', ['translationKey'], firstError))
    ] || ''

  const firstErrorTranslationKey = firstError?.translationKey || ''
  const translate = I18n.useTranslate(
    () => [
      'transaction.field-validation.card-number.invalid',
      errorKey,
      firstErrorTranslationKey,
    ],
    [errorKey, firstErrorTranslationKey]
  )
  const visited = field.status !== Forms.FieldStatus.PRISTINE
  const value = field.value && field.value.toString()
  const isCardNumberValid =
    field.name === 'encCreditcardNumber' ? validateCardNumber(value) : true
  const valid = R.isEmpty(field.errors) && isCardNumberValid
  const visuallyValid = !visited || valid
  const visible = !visuallyValid || (!valid && visited)

  if (!visible || valid) {
    return null
  }
  return (
    <ThemeUi.Message variant="fieldError">
      <ThemeUi.Grid
        columns="max-content 1fr"
        gridGap="4px"
        sx={{
          alignItems: 'center',
        }}
      >
        <Icons.Error />
        {
          // Check first if a custom translation exists. If nothing exists for
          // the key, the value will be null and we can fallback to the generic
          // translation.
          !R.isEmpty(field.errors)
            ? translate(
                firstErrorTranslationKey,
                firstError.translationVariables
              ) || translate(errorKey, firstError.translationVariables)
            : translate('transaction.field-validation.card-number.invalid')
        }
      </ThemeUi.Grid>
    </ThemeUi.Message>
  )
}

_FieldResponse.propTypes = {
  scope: PropTypes.string.isRequired,
}

/**
 * Component that wraps _FieldResponse with a Suspense Boundary.
 * Used to catch suspense so not to trigger app-wide boundary.
 * @component FieldResponse
 * @returns {ReactNode}
 */
export function FieldResponse(props) {
  return (
    <Suspense.Boundary fallback={Suspense.Fallbacks.Empty}>
      <_FieldResponse {...props} />
    </Suspense.Boundary>
  )
}
