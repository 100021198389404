import * as Forms from '@rushplay/forms'
import * as Hooks from './hooks'
import * as I18n from '@rushplay/i18n'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Component to handle transactions via HelloClever
 * @component HelloCleverProcessing
 * @returns {ReactNode} Iframe or redirection link
 */
export function HelloCleverProcessing() {
  const payId = ReactRedux.useSelector((state) =>
    R.path(['activeTransaction', 'redirectOutput', 'payId'], state.payments)
  )

  const nonceCode = ReactRedux.useSelector((state) =>
    R.path(['activeTransaction', 'redirectOutput', 'nonceCode'], state.payments)
  )

  const amountCents = Forms.useField('#/properties/amount', {noRegister: true})
    .value
  const amount = Hooks.useToAmount({
    amountCents: amountCents,
    minimumFractionDigits: '0',
  })

  const translate = I18n.useTranslate()

  if (payId != null && nonceCode != null) {
    return (
      <ThemeUi.Flex
        sx={{
          pt: 2,
          justifyContent: 'center',
        }}
      >
        <ThemeUi.Grid
          sx={{
            fontSize: 1,
            gridGap: 1,
            lineHeight: 'body',
            textAlign: 'center',
            flexGrow: 1,
            margin: '10px',
          }}
        >
          <p>
            {translate('hello-clever-processing.payment-instructions', {
              amount: amount,
              nonceCode: nonceCode,
            })}
          </p>
          <ThemeUi.Box>
            <ThemeUi.Field value={payId} readOnly />
          </ThemeUi.Box>
        </ThemeUi.Grid>
      </ThemeUi.Flex>
    )
  } else {
    return (
      <ThemeUi.Flex
        sx={{
          pt: 2,
          justifyContent: 'center',
        }}
      >
        <ThemeUi.Grid
          sx={{
            fontSize: 1,
            gridGap: 1,
            lineHeight: 'body',
            textAlign: 'center',
            flexGrow: 1,
          }}
        >
          <ThemeUi.Box
            sx={{
              pb: 2,
              fontSize: 5,
            }}
          >
            <ThemeUi.Spinner />
          </ThemeUi.Box>
        </ThemeUi.Grid>
      </ThemeUi.Flex>
    )
  }
}

HelloCleverProcessing.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}

I18n.Loader.preload(
  ['hello-clever-processing.payment-instructions'],
  HelloCleverProcessing
)
