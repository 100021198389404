import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Payments from './payments'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Reselect from 'reselect'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'
import Url from 'url'

function Form(props) {
  const ref = React.useRef()
  const url = Url.parse(props.redirectParams.url, true)

  React.useEffect(() => {
    if (ref.current) {
      ref.current.submit()
    }
  }, [ref])

  return (
    <form
      action={props.redirectParams.url}
      method={props.redirectParams.method}
      ref={ref}
      target={props.target}
      data-testid="form"
    >
      {R.map(
        (key) => (
          <input key={key} name={key} type="hidden" value={url.query[key]} />
        ),
        R.keys(url.query)
      )}
    </form>
  )
}

Form.propTypes = {
  redirectParams: PropTypes.shape({
    method: PropTypes.oneOf(['GET', 'POST']),
    url: PropTypes.string.isRequired,
  }),
  target: PropTypes.string.isRequired,
}

function ManualLink(props) {
  const translate = I18n.useTranslate()

  // Note: This was originally added for iPhone users using Volt, after Apple started to
  // more block popups (without indicating that a popup was blocked).
  return (
    <React.Fragment>
      <ThemeUi.Box
        sx={{
          pt: 2,
          fontSize: 1,
          lineHeight: 'body',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <ThemeUi.Text sx={{fontWeight: 'bold'}}>
          {translate('active-transaction.manual-link.info')}&nbsp;
        </ThemeUi.Text>
        <ThemeUi.Button
          as="a"
          href={props.redirectParams.url}
          rel="noopener noreferrer"
          target="_blank"
          sx={{mt: 1}}
        >
          {translate('active-transaction.manual-link.link')}
        </ThemeUi.Button>
      </ThemeUi.Box>
    </React.Fragment>
  )
}

ManualLink.propTypes = {
  redirectParams: PropTypes.shape({
    method: PropTypes.oneOf(['GET', 'POST']),
    url: PropTypes.string.isRequired,
  }),
}

// eslint-disable-next-line no-unused-vars
export function NodapayProcessing(props) {
  const translate = I18n.useTranslate()
  const [iframeSrc, setIframeSrc] = React.useState(null)

  // prettier-ignore
  const amountCents = Forms.useField('#/properties/amount', {noRegister: true}).value
  const apiUrl = ReactRedux.useSelector(Configuration.getApiUrl)
  const getPlayerData = Reselect.createStructuredSelector({
    sessionToken: Configuration.getToken,
    countryCode: Configuration.getCountryCode,
  })
  const playerData = ReactRedux.useSelector(getPlayerData)
  const {transactionType} = Payments.useTransactionContext()

  React.useEffect(() => {
    function messageReceive(event) {
      if (event.key == 'nodapayMessage') {
        const message = JSON.parse(event.newValue)

        switch (message.result) {
          case 'done':
            props.onStepChange(Constants.TransactionStep.Success)
            break

          case 'failed':
            props.onStepChange(Constants.TransactionStep.Failure)
            break

          default:
            props.onStepChange(Constants.TransactionStep.Failure)
        }

        localStorage.removeItem('nodapayMessage')
      }
    }

    window.addEventListener('storage', messageReceive)

    return () => window.removeEventListener('storage', messageReceive)
  })

  React.useEffect(() => {
    const cashierJson = {
      amount_cents: amountCents,
      transaction_type: transactionType,
    }

    fetch(`${apiUrl}/nodapay/payments`, {
      method: 'POST',
      headers: {
        'Accept': 'application/vnd.casinosaga.v1, application/json',
        'Authorization': playerData.sessionToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cashierJson),
    })
      .then((response) => response.json())
      .then((data) => {
        setIframeSrc(data.url)
      })
  }, [apiUrl, playerData.sessionToken, transactionType, amountCents])

  if (!iframeSrc) {
    return (
      <React.Fragment>
        <ThemeUi.Flex sx={{justifyContent: 'center'}}>
          <ThemeUi.Spinner />
        </ThemeUi.Flex>
      </React.Fragment>
    )
  }

  if (playerData.countryCode === 'DE') {
    return (
      <>
        <Form
          target="_blank"
          redirectParams={{url: iframeSrc, method: 'GET'}}
        />
        <ThemeUi.Flex
          sx={{
            pt: 2,
            fontSize: 1,
            lineHeight: 'body',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {translate('active-transaction.successful-redirection')}
        </ThemeUi.Flex>

        {translate('active-transaction.manual-link.info') != null && (
          <ManualLink redirectParams={{url: iframeSrc, method: 'GET'}} />
        )}
      </>
    )
  } else {
    return (
      <ThemeUi.Flex
        sx={{
          'alignItems': 'center',
          'justifyContent': 'center',
          'flexDirection': 'column',
          'height': '100%',
          '#nodapay-payment-system': {
            height: '100%',
            width: '100%',
            maxWidth: '600px',
          },
        }}
      >
        <iframe
          src={iframeSrc}
          sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
          style={{border: 'none'}}
        />
      </ThemeUi.Flex>
    )
  }
}

NodapayProcessing.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}
