import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {Image} from '../image'
import PropTypes from 'prop-types'
import Url from 'url'

/**
 * Component that renders a Selector with available cryptoCurrencies for CryptoPay.
 * @component CryptopayCurrencySelector
 * @returns {ReactNode}
 */
export function CryptopayCurrencySelector(props) {
  const [cryptoCurrency, setCryptoCurrency] = React.useState(null)
  const cryptopayCurrency = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  const translate = I18n.useTranslate()

  function imageSource(currency) {
    return Url.parse(
      translate(`cryptocurrency.${currency.toLowerCase()}`) || ''
    )
  }

  const handleChange = React.useCallback(
    (currency) => {
      setCryptoCurrency(currency)
      cryptopayCurrency.onChangeValue(currency)
    },
    [cryptopayCurrency]
  )

  return (
    <ThemeUi.Grid
      sx={{
        gap: [0, 1],
        gridTemplateColumns: 'repeat(auto-fit, minmax(105px, 1fr))',
      }}
    >
      {props.options.map((option) => (
        <ThemeUi.Box
          display="flex"
          as="label"
          key={option.value}
          sx={{
            'border': '2px solid',
            'position': 'relative',
            'backgroundColor': 'paymentMethodText',
            'borderColor': 'muted',
            'textAlign': 'center',
            'alignItems': 'center',
            'justifyContent': 'center',
            'borderRadius': '4px',
            'px': 0,
            'py': 1,
            'cursor': 'pointer',
            '&:hover': {
              borderColor: 'primary',
            },
            '&:active': {
              transform: 'scale(0.975)',
            },
            '&:focus-within': {
              outline: '2px solid',
              outlineColor: 'success',
            },
          }}
        >
          <ThemeUi.Box
            as="input"
            sx={{
              opacity: 0,
              zIndex: '-1',
              overflow: 'hidden',
              position: 'absolute',
            }}
            checked={cryptoCurrency === option.value}
            name={option.value}
            type="radio"
            value={option.value}
            onChange={() => handleChange(option.value)}
          />
          {cryptoCurrency === option.value && (
            <ThemeUi.Box
              sx={{
                position: 'absolute',
                top: '4px',
                right: '4px',
              }}
            >
              <Image
                alt="checked"
                source={imageSource('checked').pathname || ''}
                resizingHeight={15}
                sx={{
                  maxHeight: '15px',
                }}
              />
            </ThemeUi.Box>
          )}
          <Image
            alt={option.value}
            source={imageSource(option.value).pathname || ''}
            resizingHeight={50}
            sx={{
              maxHeight: '50px',
            }}
          />
        </ThemeUi.Box>
      ))}
    </ThemeUi.Grid>
  )
}

CryptopayCurrencySelector.propTypes = {
  initialValue: PropTypes.string,
  options: PropTypes.array,
  scope: PropTypes.string.isRequired,
}

I18n.Loader.preload(
  [
    'cryptocurrency.btc',
    'cryptocurrency.ltc',
    'cryptocurrency.eth',
    'cryptocurrency.usdt',
    'cryptocurrency.xrp',
    'cryptocurrency.usdc',
    'cryptocurrency.bch',
    'cryptocurrency.dai',
    'cryptocurrency.xlm',
    'cryptocurrency.trx',
    'cryptocurrency.bnb',
    'cryptocurrency.ada',
    'cryptocurrency.doge',
    'cryptocurrency.sol',
    'cryptocurrency.shib',
    'cryptocurrency.checked',
  ],
  CryptopayCurrencySelector
)
