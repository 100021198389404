import * as Constants from './constants'
import * as Data from './data'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Icons from './icons'
import * as Payments from './payments'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import {HtmlContent} from './html-content'
import PropTypes from 'prop-types'

/**
 * Component to render when a transaction was failed
 * @component Failure
 * @param {Object} props Components props
 * @param {func} props.onStepChange callback to change payment step
 * @returns {ReactNode} component that shows why it failed and cta to restart
 */
export function Failure(props) {
  const form = Forms.useFormContext()
  const dispatch = ReactRedux.useDispatch()
  const forwardData = ReactRedux.useSelector(Data.getForwardData)
  const transactionError = ReactRedux.useSelector((state) =>
    Payments.getTransactionError(state.payments)
  )

  // TODO: it's not an optimal way to get teh error keys
  // piq returns an array, then we transform it to a string then to the array again
  // this should be imporved in the future to avoid this kind of transformation
  const errorsList = transactionError?.split(',')
  const errorKeyLsit = errorsList?.map((error) => `errors.${error}`) || []

  const translate = I18n.useTranslate(() => errorKeyLsit, [errorKeyLsit])

  {
    /* If the error is untranslated show the generic error */
  }
  const translatedKey =
    errorKeyLsit.find((key) => translate(key)) || 'failure.generic-error'

  React.useEffect(() => {
    dispatch(Data.transactionFailure(forwardData))
  }, [dispatch, forwardData])

  function handleStartOver() {
    // When a transaction fails and we want to restart we need to reset payments redux-state
    dispatch(Payments.resetTransaction())
    // We clear the form to have the user start over with their selections
    // and correct any potential errors
    form.destroyFields({
      exclude: ['#/properties/amount'],
    })
    props.onStepChange(Constants.TransactionStep.PaymentMethod)
  }

  return (
    <ThemeUi.Grid
      sx={{
        pt: 2,
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 1,
        color: 'text',
        gridGap: 1,
      }}
    >
      <ThemeUi.Box
        sx={{
          color: 'danger',
          fontSize: '120px',
          pb: 2,
        }}
      >
        <Icons.Error />
      </ThemeUi.Box>
      <HtmlContent>{translate(translatedKey)}</HtmlContent>
      <ThemeUi.Flex sx={{justifyContent: 'center'}}>
        <ThemeUi.Button onClick={handleStartOver}>
          {translate('failure.cta')}
        </ThemeUi.Button>
      </ThemeUi.Flex>
    </ThemeUi.Grid>
  )
}

Failure.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}

I18n.Loader.preload(['failure.generic-error', 'failure.cta'], Failure)
