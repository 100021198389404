import * as I18n from '@rushplay/i18n'
import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Component used for FieldBuilder, to be able to generate custom UI-schemas
 * To be used for pure information with no values gathering.
 * @component Text
 * @param {Object} props Components props
 * @param {ReactNode} props.children A component to be wrapped by Box
 * @param {*=} props.* Any valid style-in-js prop @see {@link https://theme-ui.com/guides/object-styles}
 * @returns {ReactNode} Component that wraps children
 */
export function Text(props) {
  const translate = I18n.useTranslate(() => [props.children], [props.children])
  return (
    <ThemeUi.Text
      sx={{
        ...R.omit(['children'], props),
      }}
    >
      {translate(props.children)}
    </ThemeUi.Text>
  )
}

Text.propTypes = {
  children: PropTypes.node,
}
