import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Data from './data'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Icons from './icons'
import * as Payments from './payments'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Reselect from 'reselect'
import * as SignUp from './sign-up'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Component to create Swish payment implicitly for a known phone number
 * @param {Object} props Components props
 * @param {func} props.onPaymentStart Callback to fire when payment should start
 * @param {string} props.phoneNumber Users phone number
 * @returns {ReactNode}
 */
function PaymentCreator(props) {
  React.useEffect(() => {
    props.onPaymentStart(props.phoneNumber)
  }, [props.phoneNumber, props.onPaymentStart])

  return null
}

/**
 * Selector for data to process with the transaction
 * @param {Object} state Redux state
 * @returns {Object} configurations for projs
 */
const getProjsData = Reselect.createStructuredSelector({
  affiliateClickId: Data.getAffiliateClickId,
  brand: Configuration.getBrand,
  clientType: Configuration.getClientType,
  countryCode: Configuration.getCountryCode,
  currency: Configuration.getCurrency,
  language: Configuration.getLanguage,
  btag: Data.getNetrefererBtag,
  depositOfferId: Configuration.getOfferId,
  utmCampaign: Data.getUtmCampaign,
  utmMedium: Data.getUtmMedium,
  utmSource: Data.getUtmSource,
})

/**
 * Component to handle transactions via PaymentIQ-provided payment-methods
 * @component PiqProcessing
 * @param {Object} props Component props
 * @param {('deposit' | 'signin')} props.orderType Type of transaction to process
 * @param {func} props.onStepChange Callback to change step
 * @returns {ReactNode} Iframe or redirection link
 */
export function ProjsProcessing(props) {
  const transaction = Payments.useTransactionContext()
  const dispatch = ReactRedux.useDispatch()
  const projsData = ReactRedux.useSelector(getProjsData)
  const projsConfig = ReactRedux.useSelector(Configuration.getProjsConfig)
  const origin = ReactRedux.useSelector(Configuration.getOrigin)
  const form = Forms.useFormContext()
  const formData = Forms.getFormData(form.state)
  const translate = I18n.useTranslate()

  function handleSuccess(sessionToken) {
    dispatch(Data.updateForwardingData({sessionToken}))
    props.onStepChange(Constants.TransactionStep.Success)
  }

  function handleFailure(error) {
    dispatch(Data.updateForwardingData({error}))
    props.onStepChange(Constants.TransactionStep.Failure)
  }

  const projs = SignUp.useProjs({
    ...projsData,
    ...projsConfig,
    amountCents: formData.amount,
    orderId: R.path(['redirectParams', 'parameters', 'orderId'], transaction),
    orderMethod: formData.bank_id_type,
    orderType: props.orderType,
    paymentMethod: 'swish',
    personalNumber: formData.personal_number,
    url: `${origin}/callbacks/projs`,
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    onStateChange: () => {},
    onRedirect: () => {},
  })

  return (
    <ThemeUi.Flex
      sx={{
        flexGrow: 1,
        flexDirection: 'column',
        maxWidth: '400px',
        alignItems: 'center',
        mx: 'auto',
        pt: 1,
        px: 0,
      }}
    >
      {projs.messageText && (
        <ThemeUi.Message
          variant="info"
          sx={{
            mb: 2,
          }}
        >
          <ThemeUi.Grid
            columns="max-content 1fr"
            gridGap="4px"
            sx={{
              alignItems: 'center',
            }}
          >
            <Icons.Info />
            {translate(projs.messageText)}
          </ThemeUi.Grid>
        </ThemeUi.Message>
      )}

      {projs.messageText === 'projs.status.pending' && <ThemeUi.Spinner />}

      {projs.qrCode && (
        <ThemeUi.Image src={projs.qrCode} alt="BankID QR-code" />
      )}

      {projs.redirectUrl && (
        <ThemeUi.Button
          as="a"
          rel="noopener noreferrer"
          target="_blank"
          sx={{mt: 1}}
          href={projs.redirectUrl}
        >
          {translate('projs-processing.open-app', {
            provider:
              projs.messageText === 'projs.status.swish' ? 'Swish' : 'BankID',
          })}
        </ThemeUi.Button>
      )}

      {projs.onPaymentStart && (
        <PaymentCreator
          phoneNumber={
            R.path(
              ['redirectParams', 'parameters', 'phoneNumber'],
              transaction
            ) || formData.phone_number
          }
          onPaymentStart={projs.onPaymentStart}
        />
      )}
    </ThemeUi.Flex>
  )
}

ProjsProcessing.propTypes = {
  orderType: PropTypes.oneOf(['signin', 'deposit']).isRequired,
  onStepChange: PropTypes.func.isRequired,
}

I18n.Loader.preload(
  [
    // Projs keys (from messageText) ¿ Use dynamically instead ?
    'projs.status.bankid',
    'projs.status.completed',
    'projs.status.failed',
    'projs.status.pending',
    'projs.status.starting-payment',
    'projs.status.swish',
    // Internal keys
    'projs-processing.open-app',
  ],
  ProjsProcessing
)
