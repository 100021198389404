import * as Configuration from './configuration'
import * as I18n from '@rushplay/i18n'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import {MissingQueryParamsError} from './errors'

/**
 * UI-suger page for integration errors
 * @component MissingQueries
 * @returns {ReactNode}
 */
export function MissingQueries() {
  const translate = I18n.useTranslate()
  const missingQueries = ReactRedux.useSelector(
    Configuration.getMissingRequiredQueries
  )

  if (process.env.NODE_ENV === 'production') {
    throw new MissingQueryParamsError(missingQueries)
  }

  return (
    <ThemeUi.Flex
      sx={{
        flexGrow: 1,
        flexDirection: 'column',
        maxWidth: '200px',
        alignItems: 'center',
        mx: 'auto',
        fontSize: 1,
        pt: 2,
      }}
    >
      <ThemeUi.Box
        sx={{
          color: 'danger',
          alignSelf: 'center',
          fontSize: '120px',
          pb: 2,
        }}
      >
        <Icons.Error />
      </ThemeUi.Box>
      <ThemeUi.Text
        sx={{
          fontWeight: 'bold',
          pb: 0,
        }}
      >
        {translate('missing-queries.title')}
      </ThemeUi.Text>
      {R.map(
        (item) => (
          <code key={item}>{item}</code>
        ),
        missingQueries
      )}
    </ThemeUi.Flex>
  )
}

I18n.Loader.preload(['missing-queries.title'], MissingQueries)
