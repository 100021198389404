import * as Configuration from '../configuration'
import * as Data from '../data'
import * as I18n from '@rushplay/i18n'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'
import * as Url from 'url'

/**
 * Selector that gets the url for MuchBetter-registraiton
 * @param {Object} state Redux state
 * @returns {string} formatted url
 */
function getMuchBetterSignUpUrl(state) {
  const piqConfig = Configuration.getPiqConfig(state)

  if (!piqConfig.enabled || !piqConfig.muchBetterTrackingId) {
    return null
  }

  const phonenumber = Data.getPhoneNumber(state)

  return Url.format({
    protocol: 'https',
    hostname: 'a.api.muchbetter.com',
    pathName: '/merchant/user',
    query: {
      phonenumber,
      trackingCode: piqConfig.muchBetterTrackingId,
    },
  })
}

/**
 * Component that renders a url to MuchBetter-registrations
 * @component MuchBetterSignUpLink
 * @returns {ReactNode}
 */
export function MuchBetterSignUpLink() {
  const signUpUrl = ReactRedux.useSelector(getMuchBetterSignUpUrl)
  const translate = I18n.useTranslate()

  if (!signUpUrl) {
    return null
  }

  return (
    <ThemeUi.Box
      sx={{
        textAlign: 'center',
        lineHeight: 'body',
        fontSize: 0,
        marginTop: 0,
      }}
    >
      {translate('much-better-sign-up-link.title')}
      <ThemeUi.Button
        as="a"
        rel="noopener noreferrer"
        target="_blank"
        sx={{mt: 1}}
        href={signUpUrl}
      >
        {translate('much-better-sign-up-link.link')}
      </ThemeUi.Button>
    </ThemeUi.Box>
  )
}

I18n.Loader.preload(
  ['much-better-sign-up-link.title', 'much-better-sign-up-link.link'],
  MuchBetterSignUpLink
)
